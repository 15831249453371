<template>
  <div class="col-12" v-html="getHtml" v-if="getPagina"></div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    paginas: {
      type: Array,
    },
    rota: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(['getPagina']),
    getHtml() {
      return this.getPagina.html;
    },
  },
  methods: {
    ...mapActions(['setPagina']),
  },
  async mounted() {
    const pagina = this.paginas.find((resp) => resp.rota == this.rota);
    await this.setPagina(pagina.paginaEstaticaId);
  },
};
</script>

<style>
.image-page {
  width: 100%;
}
</style>
